
import { defineComponent, reactive } from '@vue/composition-api'
import { mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'CommonDeleteIconBtn',
  setup() {
    return reactive({
      icons: {
        mdiDelete,
      },
    })
  },
})
