var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._g({
    staticClass: "mx-1",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, _vm.$listeners), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }